import {MediaContentShowcaseItem} from "@onnit-js/ui/components/module/MediaContentShowcase";

const ingredients: MediaContentShowcaseItem[] = [
    {
        image: `Ingredient-1.png?q=75&auto=compress,format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "L-theanine",
        summary:
            'An amino acid that supports the release of dopamine and serotonin in the brain &mdash; two hormones that help induce relaxation via alpha wave activity.<span class="oui-sup">&dagger;</span>',
        detail:
            'Supports alpha brain wave activity, promoting &ldquo;flow state&rdquo;&mdash;the feeling of being in the zone.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `Ingredient-2.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: 'Alpha GPC',
        summary: 'A compound that supplies choline, a precursor to the neurotransmitter acetylcholine.',
        detail: 'Supports acetylcholine levels.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `Ingredient-3.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: 'Huperzia serrata extract',
        summary: 'This clubmoss contains Huperzine A.',
        detail: 'Shown to help stop the breakdown of acetylcholine.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `Ingredient-4.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: 'Vitamin B6',
        summary: 'A water-soluble vitamin involved in more than 100 physiological reactions.',
        detail: 'Has been shown to play an important role in neurotransmitter production.<span class="oui-sup">&dagger;</span>',
    },
];

export default ingredients;
