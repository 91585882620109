import React from "react";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import Box from "@onnit-js/ui/components/box/Box";
import ingredients from "./ingredients";
import MediaContentShowcase from "@onnit-js/ui/components/module/MediaContentShowcase";
import {Img} from "@onnit-js/ui/components/image";
import Text, {Sup} from "@onnit-js/ui/components/text";

export default function ({ imageUrl }: { imageUrl: string }) {
    const AB_IMG_URL = imageUrl;

    return (
        <Box bg="black" py={[6, 6, 6, 8]}>
            <Box>
                <Grid mb={4}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 12"]} pt={3} px={[4, 4, 4, 0]} mb={4}>
                        <Text role="heading" aria-level={2} as="p" typeStyle="copyDisplay02" color="white">
                            Key Ingredients
                        </Text>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 8"]} px={[4, 4, 4, 0]}>
                        <Text as="p" typeStyle="text02" color="white">
                            With Onnit’s distinct ingredient blends, Alpha BRAIN
                            <Sup variant="reg" /> Instant helps build an environment in which the brain can operate on 
                            all cylinders, supporting mental clarity.
                            <Sup />
                        </Text>
                    </Box>
                </Grid>

                <MediaContentShowcase
                    mb={[6]}
                    items={ingredients.map((x) => {
                        let copy = { ...x };
                        copy.image = `${AB_IMG_URL}/${x.image}`;
                        return copy;
                    })}
                    textColor="#6e6e6e"
                    activeColor="white"
                    bgMedia={
                        <Img
                            placeholder={[754, 208]}
                            src={`${AB_IMG_URL}/Ingredient-Background.png?q=75&auto=format`}
                            alt="River background for Flow Blend"
                        />
                    }
                />
            </Box>
        </Box>
    );
}
